import { useState, useEffect, createContext, useRef } from "react";
import moment from "moment";

export const DataContext = createContext({});

function ThemeProvider({ children }) {
  const ref = useRef(null);
  const now = new Date();
  now.setMinutes(now.getMinutes() + 1 - now.getTimezoneOffset());
  const ddL = new Date();
  ddL.setHours(ddL.getHours() + 15);

  const [responseGetById, setResponseGetById] = useState(null);
  const [exitAddress, setExitaddress] = useState({
    cityName: "",
    streetName: "",
    streetNum: "",
  });
  const [errorAutoComplet, setErrorAutoComplet] = useState(false);
  const [errorDDline, setErrorDDline] = useState(false);
  const [errorPickUp, setErrorPickUp] = useState(false);
  const [address, setAddress] = useState("");
  const [detailsOfPackage, setDetailsOfPackage] = useState([]);
  const [refBackToDetails, setDefBackToDetails] = useState(null);
  const [focus, setFocus] = useState(null);

  const [time, setTime] = useState({
    ddLine: moment().add(1, "days").format("YYYY-MM-DD[T]HH:mm"),
    pickUpTime: now.toISOString().substring(0, 16),
  });

  const [nameAndPhone, setNameAndPhone] = useState({
    fullName: "",
    phoneNumber: "",
    regulations: true,
    area_code: "+972",
  });
  const [activeStep, setActiveStep] = useState(0);
  const [checkboxValue, setCheckboxValue] = useState({
    MotorcycleChecked: "0",
    CarChecked: "0",
    TruckChecked: "0",
  });
  const [getDataFromServer, setGetDataFromServer] = useState({
    track_id: null,
    new_price: 0,
    price_min: 0,
    track_minutes: null,
    track_distance: null,
    count_orders: null,
    currency_symbol: "₪",
    isLoading: null,
  });
  const clone_price_min = Object.assign({}, getDataFromServer);
  const [val, setVal] = useState(0);
  const [isFileXl, setIsFileXl] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showSteps, setShowSteps] = useState(false);
  const [openMoreDetailsPopUp, setOpenMoreDetailsPopUp] = useState(false);
  const [loadingTakesTime, setLoadingTakesTime] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateServer, setIsUpdateServer] = useState(false);
  const [errorsPhoneName, setErrorsPhoneName] = useState({
    name: false,
    phone: false,
  });
  const [errorsVehicle, setErrorsVehicle] = useState({
    MotorcycleChecked: "0",
    CarChecked: "0",
    TruckChecked: "0",
  });

  const [errorsTerms, setErrorsTerms] = useState(false);
  const [responseSmsValid, setResponseSmsValid] = useState(null);
  const [verificationCheck, setVerificationCheck] = useState(null);

  const switchEditBtnSummary = (key) => {
    switch (key) {
      case "locationAndDateOfPackage":
        setShowSteps(false);

      case "detailsOfPackage":
        setActiveStep((prevActiveStep) => prevActiveStep - 4);
        break;
      case "checked":
        setActiveStep((prevActiveStep) => prevActiveStep - 3);
        break;
      case "payAmount":
        getDataFromServer.isLoading = false;
        setGetDataFromServer({ ...getDataFromServer });
        setActiveStep((prevActiveStep) => prevActiveStep - 2);
        break;
      case "fullNameAndPhone":
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        break;
      default:
        break;
    }
  };

  let components;
  if (nameAndPhone.phoneNumber && nameAndPhone.fullName !== "") {
    const phoneNumber = nameAndPhone.phoneNumber;
    const countryCodeLength = phoneNumber.length - 9;

    components = {
      IDDCC: phoneNumber.substr(0, countryCodeLength),
      NN: phoneNumber.substr(countryCodeLength, phoneNumber.length),
    };
  }

  return (
    <DataContext.Provider
      value={{
        components,
        showButton,
        setShowButton,
        openMoreDetailsPopUp,
        setOpenMoreDetailsPopUp,
        verificationCheck,
        setVerificationCheck,
        responseSmsValid,
        setResponseSmsValid,
        errorsPhoneName,
        setErrorsPhoneName,
        errorsVehicle,
        setErrorsVehicle,
        errorsTerms,
        setErrorsTerms,
        isUpdateServer,
        setIsUpdateServer,
        loadingTakesTime,
        setLoadingTakesTime,
        showInput,
        setShowInput,
        isLoading,
        setIsLoading,
        responseGetById,
        setResponseGetById,
        exitAddress,
        setExitaddress,
        errorAutoComplet,
        setErrorAutoComplet,
        getDataFromServer,
        setGetDataFromServer,
        errorDDline,
        setErrorDDline,
        errorPickUp,
        setErrorPickUp,
        address,
        setAddress,
        detailsOfPackage,
        setDetailsOfPackage,
        time,
        setTime,
        val,
        setVal,
        ref,
        switchEditBtnSummary,
        nameAndPhone,
        setNameAndPhone,
        checkboxValue,
        setCheckboxValue,
        activeStep,
        setActiveStep,
        isFileXl,
        setIsFileXl,
        showSteps,
        setShowSteps,
        refBackToDetails,
        setDefBackToDetails,
      }}
    >
      {" "}
      {children}{" "}
    </DataContext.Provider>
  );
}

export default ThemeProvider;
